@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");

:root {
  scroll-behavior: smooth;
}

body,
html {
  height: 100%;
}

body {
  padding: 0;
  margin: 0;
  font-size: 17px;
  font-family: "Montserrat", sans-serif;
  overflow-x: hidden;
}

img {
  max-width: 100%;
  height: auto;
}

.box-shadow {
  box-shadow: 0px 0px 15px 2px rgb(0 0 0 / 10%);
}

p,
span,
div,
button {
  font-family: "Montserrat", sans-serif;
}

:focus {
  outline: 0 !important;
}

.d-table {
  width: 100%;
  height: 100%;
}

.d-table-cell {
  vertical-align: middle;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif;
  color: #636363;
}

p {
  color: #6b6b84;
  line-height: 1.6;
  margin-bottom: 15px;
  font-weight: 500;
  font-size: 17px;
}

p:last-child {
  margin-bottom: 0;
}

.artical-view {
  color: #1773ea;
  text-decoration: underline;
}

.artical-view:hover {
  color: darkorchid;
  text-decoration: none;
}

a {
  color: #ff5d22;
  text-decoration: none;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

a:hover {
  color: #1773ea;
  text-decoration: none;
}

.form-group {
  margin-bottom: 20px;
}

.ptb-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}

.pt-100 {
  padding-top: 100px;
}

.pb-100 {
  padding-bottom: 100px;
}

.ptb-70 {
  padding-top: 70px;
  padding-bottom: 70px;
}

.pt-70 {
  padding-top: 70px;
}

.pb-70 {
  padding-bottom: 70px;
}

.bg-f8fbfe {
  background-color: #f8fbfe;
}

.dropdown .btn:focus {
  box-shadow: none;
}

.dropdown-item:hover {
  background-color: #e8f1fd;
  color: #1773EA;
}

.default-btn:hover,
.default-btn:focus,
.default-btn.btn-secondary.disabled,
.default-btn.btn-secondary:disabled {
  color: #fff;
  background-color: #1773ea;
  border-color: #1773ea;
  box-shadow: none;
}

.form-control:focus {
  outline: unset;
  box-shadow: unset;
}

/* btn default color changes */
.btn-primary,
.btn-primary:hover,
.btn-primary:focus {
  color: #fff;
  background-color: #1773EA;
  border-color: #1773EA;
}

/* banner section */
.banner-wrapper-content .sub-title {
  display: block;
  color: #1773ea;
  font-weight: 600;
}

.banner-wrapper-content h1 {
  font-size: 48px !important;
  margin-bottom: 18px;
  color: #636363;
  letter-spacing: 0.4px;
  line-height: 70px;
}

/*default-btn*/
.default-btn {
  border: 1px solid #1773ea;
  position: relative;
  display: inline-block;
  text-align: center;
  overflow: hidden;
  z-index: 1;
  color: #ffffff;
  background-color: #1773ea;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  border-radius: 5px;
  padding: 8px 22px;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  line-height: normal;
  letter-spacing: 0.4px;
}

.default-btn:hover {
  background-color: transparent;
  color: #1773ea;
}

.default-btn-cancel {
  border: 1px solid #1773ea;
  background-color: transparent;
  color: #1773ea;
}

/*section-title*/
.section-title {
  text-align: center;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 32px;
}

.section-title .sub-title {
  position: relative;
  display: block;
  text-transform: uppercase;
  margin-bottom: 15px;
  color: #1773ea;
  font-weight: 700;
  font-size: 15px;
}

.section-title h2 {
  margin-bottom: 0;
  position: relative;
  display: inline-block;
  font-size: 44px;
}

.section-title p {
  display: block;
  font-size: 14px;
  font-weight: 400;
}

/*================================================
Navbar Area CSS
=================================================*/
.tarn-nav .container-fluid {
  padding-left: 50px;
  padding-right: 50px;
}

.tarn-nav .navbar {
  position: inherit;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.tarn-nav .navbar .navbar-brand {
  font-size: inherit;
  line-height: 1;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
}

.tarn-nav .navbar ul {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
  align-items: center;
}

.tarn-nav .navbar .navbar-nav {
  margin-left: auto;
}

.tarn-nav .navbar .navbar-nav .nav-item {
  /* position: relative; */
  margin: 0 14px;
}

.tarn-nav .navbar .navbar-nav .nav-item a {
  color: #6e6b7b;
  font-size: 17px;
  font-weight: 500;
  padding-left: 0;
  padding-right: 0;
  padding-top: 25px;
  padding-bottom: 25px;
}

.tarn-nav .navbar .navbar-nav .nav-item a i {
  font-size: 18px;
  position: relative;
  top: 2px;
  display: inline-block;
  margin-left: -4px;
  margin-right: -3px;
}

.tarn-nav .navbar .navbar-nav .nav-item a:hover,
.tarn-nav .navbar .navbar-nav .nav-item a:focus,
.tarn-nav .navbar .navbar-nav .nav-item a.active {
  color: #1773ea;
}

.tarn-nav .navbar .navbar-nav .nav-item:last-child {
  margin-right: 0;
}

.tarn-nav .navbar .navbar-nav .nav-item:first-child {
  margin-left: 0;
}

.tarn-nav .navbar .navbar-nav .nav-item:hover a,
.tarn-nav .navbar .navbar-nav .nav-item.active a {
  color: #1773ea;
}

.tarn-nav .navbar .navbar-nav .nav-item .dropdown-menu {
  border: none;
  left: 0;
  z-index: 99;
  opacity: 0;
  width: 300px;
  display: block;
  border-radius: 0;
  padding: 10px 0;
  margin-top: 15px;
  position: absolute;
  visibility: hidden;
  background: #ffffff;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  /* border-top: 3px solid #1773ea; */
  border-radius: 6px;
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
}

.tarn-nav .navbar .navbar-nav .nav-item .dropdown-menu li {
  margin: 0;
}

.tarn-nav .navbar .navbar-nav .nav-item .dropdown-menu li a {
  padding: 8px;
  position: relative;
  display: block;
  color: #6e6b7b;
  font-size: 14px;
  font-weight: 600;
  border-bottom: unset;
}

.tarn-nav .navbar .navbar-nav .nav-item .dropdown-menu li a i {
  margin: 0;
  position: absolute;
  top: 50%;
  font-size: 20px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 15px;
}

.tarn-nav .navbar .navbar-nav .nav-item .dropdown-menu li a:hover,
.tarn-nav .navbar .navbar-nav .nav-item .dropdown-menu li a:focus,
.tarn-nav .navbar .navbar-nav .nav-item .dropdown-menu li a.active {
  color: #1773ea;
  background-color: #e3eefc;
}

.tarn-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu {
  top: 0;
  opacity: 0;
  left: -100%;
  margin-top: 15px;
  visibility: hidden;
}

.tarn-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a {
  color: #6e6b7b;
}

.tarn-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a:hover,
.tarn-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a:focus,
.tarn-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a.active {
  color: #1773ea;
}

.tarn-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu {
  top: 0;
  opacity: 0;
  left: 100%;
  visibility: hidden;
}

.tarn-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #ff5d22;
}

.tarn-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover,
.tarn-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus,
.tarn-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #1773ea;
}

.tarn-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
  top: 0;
  opacity: 0;
  left: -100%;
  visibility: hidden;
}

.tarn-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #ff5d22;
}

.tarn-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover,
.tarn-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus,
.tarn-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #1773ea;
}

.tarn-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
  top: 0;
  opacity: 0;
  left: 100%;
  visibility: hidden;
}

.tarn-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #ff5d22;
}

.tarn-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover,
.tarn-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus,
.tarn-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #1773ea;
}

.tarn-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
  top: 0;
  opacity: 0;
  left: -100%;
  visibility: hidden;
}

.tarn-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #ff5d22;
}

.tarn-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover,
.tarn-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus,
.tarn-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #1773ea;
}

.tarn-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
  top: 0;
  opacity: 0;
  left: 100%;
  visibility: hidden;
}

.tarn-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #ff5d22;
}

.tarn-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover,
.tarn-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus,
.tarn-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #1773ea;
}

.tarn-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: #1773ea;
}

.tarn-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
}

.tarn-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: #1773ea;
}

.tarn-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
}

.tarn-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: #1773ea;
}

.tarn-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
}

.tarn-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: #1773ea;
}

.tarn-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
}

.tarn-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li.active a {
  color: #1773ea;
}

.tarn-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
}

.tarn-nav .navbar .navbar-nav .nav-item .dropdown-menu li.active a {
  color: #1773ea;
}

.tarn-nav .navbar .navbar-nav .nav-item .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  margin-top: 0;
}

.tarn-nav .navbar .navbar-nav .nav-item:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  margin-top: 0;
}

.tarn-nav .navbar .navbar-nav .nav-item.megamenu {
  position: inherit;
}

.tarn-nav .navbar .navbar-nav .nav-item.megamenu .container {
  display: block;
  -ms-flex-wrap: unset;
  flex-wrap: unset;
  -webkit-box-align: unset;
  -ms-flex-align: unset;
  align-items: unset;
  -webkit-box-pack: unset;
  -ms-flex-pack: unset;
  justify-content: unset;
}

.tarn-nav .navbar .navbar-nav .nav-item.megamenu .dropdown-menu {
  width: 100%;
  top: auto;
  left: 0;
  margin-top: 0;
  padding: 30px 0;
  position: absolute;
  -webkit-transform: unset !important;
  transform: unset !important;
}

.tarn-nav .navbar .navbar-nav .nav-item.megamenu .dropdown-menu .megamenu-submenu li a {
  margin-top: 14px;
  color: #ff5d22;
  padding: 0;
  display: block;
  font-size: 16.4px;
  font-weight: 700;
}

.tarn-nav .navbar .navbar-nav .nav-item.megamenu .dropdown-menu .megamenu-submenu li a:hover,
.tarn-nav .navbar .navbar-nav .nav-item.megamenu .dropdown-menu .megamenu-submenu li a.active {
  color: #1773ea;
}

.tarn-nav .navbar .navbar-nav .nav-item.megamenu .dropdown-menu .megamenu-submenu li:first-child a {
  margin-top: 0;
}

.tarn-nav .navbar .navbar-nav .nav-item.megamenu .dropdown-menu .single-category-widget {
  position: relative;
  border-top: 1px solid #eeeeee;
  padding-top: 30px;
  margin-top: 30px;
}

.tarn-nav .navbar .navbar-nav .nav-item.megamenu .dropdown-menu .single-category-widget .icon {
  width: 70px;
  height: 70px;
  position: relative;
  background: #f4f4f4;
  text-align: center;
  font-size: 40px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  color: #ff5d22;
  margin-bottom: 15px;
  border-width: 1.5px;
  border-style: dashed;
  border-color: #eeeeee;
  border-radius: 50%;
}

.tarn-nav .navbar .navbar-nav .nav-item.megamenu .dropdown-menu .single-category-widget .icon i {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  right: 0;
}

.tarn-nav .navbar .navbar-nav .nav-item.megamenu .dropdown-menu .single-category-widget h3 {
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 800;
}

.tarn-nav .navbar .navbar-nav .nav-item.megamenu .dropdown-menu .single-category-widget .sub-title {
  display: block;
  margin-top: 5px;
  color: #1773ea;
  font-weight: 600;
  font-size: 15px;
}

.tarn-nav .navbar .navbar-nav .nav-item.megamenu .dropdown-menu .single-category-widget .link-btn {
  position: absolute;
  display: block;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
}

.tarn-nav .navbar .navbar-nav .nav-item.megamenu .dropdown-menu .single-category-widget:hover .icon {
  background-color: #1773ea;
  border-color: #ffffff;
  color: #ffffff;
}

.tarn-nav .navbar .others-option .option-item {
  margin-left: 30px;
}

.tarn-nav .navbar .others-option .option-item:first-child {
  margin-left: 0;
}

.tarn-nav .navbar .others-option .option-item .search-box {
  position: relative;
  width: 300px;
  border-radius: 5px;
}

.tarn-nav .navbar .others-option .option-item .search-box .input-search {
  display: block;
  width: 100%;
  height: 48px;
  border: none;
  border-radius: 5px;
  background-color: #f5f5f5;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  color: #ff5d22;
  font-size: 16px;
  font-weight: 400;
  padding-left: 15px;
  padding-top: 1px;
  padding-bottom: 0;
  padding-right: 0;
}

.tarn-nav .navbar .others-option .option-item .search-box .input-search::-webkit-input-placeholder {
  color: #848484;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.tarn-nav .navbar .others-option .option-item .search-box .input-search:-ms-input-placeholder {
  color: #848484;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.tarn-nav .navbar .others-option .option-item .search-box .input-search::-ms-input-placeholder {
  color: #848484;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.tarn-nav .navbar .others-option .option-item .search-box .input-search::placeholder {
  color: #848484;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.tarn-nav .navbar .others-option .option-item .search-box .input-search:focus {
  border-color: #1773ea;
}

.tarn-nav .navbar .others-option .option-item .search-box .input-search:focus::-webkit-input-placeholder {
  color: transparent;
}

.tarn-nav .navbar .others-option .option-item .search-box .input-search:focus:-ms-input-placeholder {
  color: transparent;
}

.tarn-nav .navbar .others-option .option-item .search-box .input-search:focus::-ms-input-placeholder {
  color: transparent;
}

.tarn-nav .navbar .others-option .option-item .search-box .input-search:focus::placeholder {
  color: transparent;
}

.tarn-nav .navbar .others-option .option-item .search-box button {
  position: absolute;
  right: 0;
  top: 0;
  height: 48px;
  background-color: transparent;
  border: none;
  color: #1773ea;
  border-radius: 5px;
  font-size: 22px;
  padding: 0 15px;
}

.tarn-nav .navbar .others-option .option-item .default-btn {
  margin-bottom: 0;
}

.navbar-area {
  position: absolute;
  background-color: transparent;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  z-index: 999;
}

.navbar-area {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
  background-color: #ffffff !important;
  transition: unset;
  animation: unset;
  padding-top: 0;
  /* padding-bottom: 5px; */
}

.navbar-toggler {
  border: none;
  background: #eee !important;
  padding: 10px;
  border-radius: 0;
}

.navbar-toggler:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.navbar-toggler .icon-bar {
  width: 28px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  background: #1773ea;
  height: 2px;
  display: block;
}

.navbar-toggler .top-bar {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transform-origin: 10% 10%;
  transform-origin: 10% 10%;
  left: 4px;
  position: relative;
}

.navbar-toggler .middle-bar {
  opacity: 0;
  margin: 6px 0;
}

.navbar-toggler .bottom-bar {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: 10% 90%;
  transform-origin: 10% 90%;
  left: 4px;
  position: relative;
}

.navbar-toggler.collapsed .top-bar {
  -webkit-transform: rotate(0);
  transform: rotate(0);
  left: 0;
}

.navbar-toggler.collapsed .middle-bar {
  opacity: 1;
}

.navbar-toggler.collapsed .bottom-bar {
  -webkit-transform: rotate(0);
  transform: rotate(0);
  left: 0;
}

.navbar-area .tarn-nav .navbar .navbar-brand img {
  max-width: 100%;
}

@media only screen and (max-width: 1199px) {
  .navbar-area {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .navbar-area.is-sticky {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .navbar-area.navbar-style-two {
    padding-top: 17px;
    padding-bottom: 17px;
  }
}

/*================================================
Main Banner Area CSS
=================================================*/

.banner-image {
  text-align: center;
}

.hero-banner-image {
  text-align: center;
}

.banner-wrapper-image {
  text-align: center;
}

/*================================================
Privacy Policy Area CSS
=================================================*/
.privacy-policy-content img {
  border-radius: 5px;
  display: block;
  width: 100%;
  margin-bottom: 30px;
}

.privacy-policy-content h3 {
  margin-bottom: 15px;
  margin-top: 25px;
  font-size: 24px;
}

.privacy-policy-content .blockquote,
.privacy-policy-content blockquote {
  margin-bottom: 30px;
  background-color: #e6f2f5;
  text-align: left !important;
  padding: 25px !important;
}

.privacy-policy-content .blockquote p,
.privacy-policy-content blockquote p {
  margin-bottom: 0;
  line-height: 1.8;
  font-size: 17px !important;
  font-weight: 700;
}

.privacy-policy-content .blockquote::after,
.privacy-policy-content blockquote::after {
  display: none;
}

.privacy-policy-content .blockquote::before,
.privacy-policy-content blockquote::before {
  display: none;
}

.privacy-policy-content ol,
.privacy-policy-content ul {
  margin-top: 20px;
}

.privacy-policy-content ol li,
.privacy-policy-content ul li {
  margin-bottom: 10px;
  color: #6b6b84;
  line-height: 1.8;
  font-weight: 600;
  font-size: 17px;
}

/*================================================
Terms of Service Area CSS
=================================================*/
.terms-of-service-content img {
  border-radius: 5px;
  display: block;
  width: 100%;
  margin-bottom: 30px;
}

.terms-of-service-content h3 {
  margin-bottom: 15px;
  margin-top: 25px;
  font-size: 24px;
}

.terms-of-service-content .blockquote,
.terms-of-service-content blockquote {
  margin-bottom: 30px;
  background-color: #e6f2f5;
  text-align: left !important;
  padding: 25px !important;
}

.terms-of-service-content .blockquote p,
.terms-of-service-content blockquote p {
  margin-bottom: 0;
  line-height: 1.8;
  font-size: 17px !important;
  font-weight: 700;
}

.terms-of-service-content .blockquote::after,
.terms-of-service-content blockquote::after {
  display: none;
}

.terms-of-service-content .blockquote::before,
.terms-of-service-content blockquote::before {
  display: none;
}

.terms-of-service-content ol,
.terms-of-service-content ul {
  margin-top: 20px;
}

.terms-of-service-content ol li,
.terms-of-service-content ul li {
  margin-bottom: 10px;
  color: #6b6b84;
  line-height: 1.8;
  font-weight: 600;
  font-size: 17px;
}

/*================================================
Contact Area CSS
=================================================*/
.contact-info-box {
  margin-bottom: 30px;
  position: relative;
  padding-left: 100px;
  z-index: 1;
}

.contact-info-box .icon {
  width: 75px;
  height: 85px;
  background-color: #f7f7f7;
  border-radius: 3px;
  position: absolute;
  text-align: center;
  left: 0;
  font-size: 40px;
  color: #1773ea;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.contact-info-box .icon i {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.contact-info-box h3 {
  margin-bottom: 10px;
  font-size: 24px;
}

.contact-info-box p {
  margin-bottom: 3px;
}

.contact-info-box p:last-child {
  margin-bottom: 0;
}

.contact-info-box .back-icon {
  position: absolute;
  right: 0;
  bottom: -15px;
  z-index: -1;
  color: #ff5d22;
  line-height: 1;
  opacity: 0.04;
  font-size: 100px;
  -webkit-transform: rotate(-5deg);
  transform: rotate(-5deg);
}

.contact-info-box:hover .icon {
  background-color: #1773ea;
  color: #ffffff;
}

.contact-image {
  margin-top: 20px;
  text-align: center;
}

.contact-form {
  padding: 40px;
  margin-left: 15px;
  -webkit-box-shadow: 0 0 20px rgba(158, 158, 158, 0.16);
  box-shadow: 0 0 20px rgba(158, 158, 158, 0.16);
  background-color: #ffffff;
}

.contact-form form .form-group .form-control {
  height: 55px;
  border-radius: 0;
}

.contact-form form .form-group textarea.form-control {
  height: auto;
}

.contact-form form .help-block ul {
  margin-bottom: 0;
  margin-top: 12px;
}

.contact-form form .help-block ul li {
  color: red;
}

.contact-form form .default-btn {
  margin-top: 5px;
}

.contact-form form #msgSubmit {
  margin: 0 !important;
}

.contact-form form #msgSubmit.text-danger,
.contact-form form #msgSubmit.text-success {
  margin-top: 15px !important;
}

#map iframe {
  width: 100%;
  height: 500px;
  border: none;
}

/*================================================
Footer Area CSS
=================================================*/
.footer-area {
  padding: 35px 0;
  position: relative;
  z-index: 1;
}

.footer-area.bg-color {
  background-color: rgb(248 251 254);
}

.single-footer-widget {
  margin-bottom: 30px;
}

.single-footer-widget .social-link {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
  margin-top: 18px;
}

.single-footer-widget .social-link li {
  display: inline-block;
  margin-right: 10px;
}

.single-footer-widget .social-link li:last-child {
  margin-right: 0;
}

.single-footer-widget .social-link li a {
  width: 34px;
  height: 34px;
  text-align: center;
  color: #ffffff;
  background-color: transparent;
  font-size: 18px;
  position: relative;
  border-radius: 2px;
}

.single-footer-widget .social-link li a i {
  position: absolute;
  left: 0;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-48%);
  transform: translateY(-48%);
}

.single-footer-widget .social-link li a:hover {
  background-color: #1773ea;
  color: #ffffff;
}

.single-footer-widget h3 {
  margin-bottom: 15px;
  font-weight: 600;
  font-size: 24px;
  color: #636363;
}

.single-footer-widget .footer-links-list {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}

.single-footer-widget .footer-links-list li {
  margin-bottom: 8px;
  color: #6b6b84;
  font-weight: 600;
}

.single-footer-widget .footer-links-list li a {
  color: #6E6B7B;
  display: inline-block;
  font-weight: 400;
  font-size: 16px;
}

.single-footer-widget .footer-links-list li a:hover {
  color: #1773ea;
}

.single-footer-widget .footer-links-list li:last-child {
  margin-bottom: 0;
}

.footer-bottom-area {
  margin-top: 70px;
  border-top: 1px solid #f3f2ee;
  padding: 5px 0;
  background-color: #1773ea;
}

.footer-bottom-area p i {
  position: relative;
  top: 1.3px;
}

.footer-bottom-area p a {
  color: #1773ea;
  font-weight: 600;
}

.footer-bottom-area p a:hover {
  color: #ff5d22;
}

.footer-bottom-area p strong {
  color: #ff5d22;
}

.footer-bottom-area ul {
  text-align: right;
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}

.footer-bottom-area ul li {
  color: #6b6b84;
  position: relative;
  display: inline-block;
  margin-right: 20px;
}

.footer-bottom-area ul li:last-child {
  margin-right: 0;
}

.footer-bottom-area ul li:last-child::before {
  display: none;
}

.footer-bottom-area ul li::before {
  content: "";
  position: absolute;
  right: -12px;
  top: 5.6px;
  width: 1px;
  height: 14px;
  background-color: #6b6b84;
}

.footer-bottom-area ul li a {
  display: block;
  color: #6b6b84;
}

.footer-bottom-area ul li a:hover {
  color: #1773ea;
}

/*================================================
Go Top CSS
=================================================*/
.go-top {
  position: fixed;
  cursor: pointer;
  bottom: -100px;
  right: 20px;
  color: #ffffff;
  background-color: #1773ea;
  z-index: 4;
  width: 45px;
  text-align: center;
  height: 45px;
  opacity: 0;
  visibility: hidden;
  border-radius: 50%;
  font-size: 22px;
  -webkit-transition: 0.9s;
  transition: 0.9s;
  overflow: hidden;
  -webkit-box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
}

.go-top i {
  position: absolute;
  right: 0;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.go-top.active {
  opacity: 1;
  visibility: visible;
  bottom: 20px;
}

.go-top:hover {
  background-color: #1773ea;
  color: #ffffff;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  -webkit-box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11),
    0 1px 3px rgba(0, 0, 0, 0.08);
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
}

/* munim changes */
/* top header */
.top-header {
  background-color: #1773ea;
  padding: 6px 0;
  color: #fff;
  height: 40px;
}

.munim-join-now {
  content: "";
  clear: both;
  display: inline-block;
  font-size: 12px;
  line-height: 18px;
  color: #fff;
  font-weight: 700;
  background: rgba(255, 255, 255, 0.12);
  padding: 5px 10px;
  border-radius: 17px;
  vertical-align: middle;
  margin: 0 0 0 10px;
}

.munim-join-now:hover {
  color: #fff;
}

.munim-top-header p {
  font-size: 14px;
  line-height: 17px;
  padding-right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row-reverse;
  direction: ltr;
  color: #fff;
}

.navbar-area.navbar-style-two {
  box-shadow: 0 0 20px rgb(24 41 47 / 10%);
}

.services-content {
  position: relative;
}

.services-content::before {
  content: "";
  position: absolute;
  height: 15px;
  width: 15px;
  background: #ffffff;
  left: -15px;
  top: 5px;
  border-radius: 100%;
  border: 0px solid #fdfdfd;
  box-shadow: 0px 0px 0px 0 #ffffff;
  border: 1.5px solid #D8D6DE;
}

.services-content span {
  color: #6e6b7b;
  padding-left: 6px;
  font-size: 14px;
}

.btn-box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
}

.radio-btn-services {
  background: #e8f1fd;
  border-radius: 5px;
  height: 38px;
  padding: 7px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  border: 0;
}

.radio-btn-services input {
  opacity: 0;
}

.radio-btn-services input:checked+.services-content::before {
  background: #1773ea;
  box-shadow: 0px 2px 4px rgba(23, 115, 234, 0.4);
}

.search-box {
  border: 1px solid #ebe9f1;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  background: #ffffff;
  padding: 5px 6px;
}

.search-box-contrainer {
  display: flex;
  justify-content: flex-start;
}

.dropdown-box {
  display: flex;
  align-items: center;
  gap: 15px;
}

.dropdown-search {
  width: 100%;
  padding-left: 1rem;
}

.search-items input {
  border: none;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 0 20px;
}

.search-items,
.dropdown-box {
  flex: 1;
}

.dropdown-box .dropdown-search div[class$="control"],
.dropdown-box .dropdown-search span[class$="indicatorSeparator"] {
  border: 0;
  background-color: unset;
  box-shadow: unset;
}

.dropdown-box .dropdown-search div[class$="control"] div[class$="ValueContainer"] div[class$="placeholder"],
.search-items input::placeholder {
  font-weight: 500;
  font-size: 15px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.header-sub-title {
  font-weight: 500;
  font-size: 30px;
  color: #1773ea;
}

/* professional services card */
.professional-card {
  margin: 15px;
  border-radius: 6px;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
}

.professional-card-user .pro-img img {
  width: 100%;
  height: 100%;
  max-height: 100%;
  max-width: 100%;
  object-fit: cover;
  border-radius: 6px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.professional-card-user .pro-img {
  height: 200px;
  width: 100%;
}

.pro-user-content {
  padding: 20px;
  color: #6e6b7b;
  position: relative;
}

.pro-user-detail .pro-user-name h4 {
  font-weight: 700;
  font-size: 14px;
  padding: 0;
  margin: 0;
  color: #6E6B7B;
}

.pro-user-detail .pro-user-name p {
  font-weight: 400;
  font-size: 14px;
  color: #6E6B7B;
}

.pro-user-detail .pro-card-favourite-icon {
  cursor: pointer;
}

.pro-user-detail .pro-card-favourite-icon i {
  color: #1773ea;
  font-size: 18px;
}

.pro-user-content .pro-user-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 6px solid white;
  overflow: hidden;
  background: #ffffff;
  box-shadow: 0px 0px 16.0526px rgb(0 0 0 / 10%);
  position: absolute;
  top: -29px;
}

.pro-user-content .pro-user-img img {
  width: 42px;
  height: 42px;
  border-radius: 50%;
}

.pro-user-detail-desc {
  height: 72px;
}

.pro-user-detail-desc p {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #636363;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.pro-services-price {
  background: rgba(23, 115, 234, 0.12);
  border-radius: 17px;
  padding: 2px 16px;
}

.pro-services-price p {
  font-size: 14px;
  color: #1773ea;
  font-weight: 600;
}

.pro-card-rating-price {
  border-top: 1px solid #f0f3f7;
}

/* munim slider */
.slick-next:before,
.slick-prev:before {
  content: none !important;
}

.slick-arrow.slick-next {
  background-image: url("data:image/svg+xml,%0A%3Csvg width='8' height='15' viewBox='0 0 8 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1.06689L7 7.53345L1 14' stroke='%236E6B7B' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: center;
  width: 40px;
  height: 40px;
  color: #ffffff;
  border-radius: 50%;
  background-color: #ffffff;
  right: -4px;
  box-shadow: 0px 0px 16.0526px rgb(0 0 0 / 10%);
}

.slick-arrow.slick-prev {
  background-image: url("data:image/svg+xml,%0A%3Csvg width='9' height='16' viewBox='0 0 9 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.15097 14.8609L1.00079 8.53705L6.84729 1.9314' stroke='%236E6B7B' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: center;
  width: 40px;
  height: 40px;
  color: #ffffff;
  border-radius: 50%;
  background-color: #ffffff;
  left: -4px;
  z-index: 9;
  box-shadow: 0px 0px 16.0526px rgb(0 0 0 / 10%);
}

.slick-arrow.slick-next:hover,
.slick-arrow.slick-next:focus {
  background-image: url("data:image/svg+xml,%0A%3Csvg width='8' height='15' viewBox='0 0 8 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1.06689L7 7.53345L1 14' stroke='%236E6B7B' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: center;
}

.slick-arrow.slick-prev:hover,
.slick-arrow.slick-prev:focus {
  background-image: url("data:image/svg+xml,%0A%3Csvg width='9' height='16' viewBox='0 0 9 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.15097 14.8609L1.00079 8.53705L6.84729 1.9314' stroke='%236E6B7B' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: center;
}

.slick-arrow.slick-next:hover,
.slick-arrow.slick-next:focus,
.slick-arrow.slick-prev:hover,
.slick-arrow.slick-prev:focus {
  background-color: #fff;
  color: #000;
}

/* categories */
.categories-card {
  justify-content: center;
  align-content: center;
  gap: 31px;
  padding: 0;
  display: flex;
  max-width: 100%;
  flex-wrap: wrap;
  margin: 0 auto;
}

.categories-box {
  background-color: #ffffff;
  border-radius: 6px;
  box-shadow: 0px 4px 24px rgba(24, 41, 47, 0.1);
  transition: 0.2s ease-in-out;
  padding: 30px 15px;
  text-align: center;
  width: 192px;
}

.categories-link:hover .categories-items p {
  color: #1773ea;
}

/* what we do */
.about-munim-img {
  background-color: #fff;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.1);
  width: 100px;
  height: 100px;
}

.about-munim-img img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.about-munim-img .number-about {
  position: absolute;
  width: 40px;
  height: 40px;
  background-color: #1773ea;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: #fff;
  left: -16px;
  bottom: 29px;
}

.about-munim-img .number-about p {
  color: #fff;
}

.about-text-head h3 {
  font-weight: 600;
  font-size: 24px;
  color: #636363;
  margin-bottom: 1rem;
}

.about-text-head p {
  font-weight: 400;
  font-size: 14px;
  color: #6e6b7b;
}

.about-text-head {
  max-width: 300px;
  margin: 0 auto;
}

/* recetly reviewd */
.recently-view-section {
  text-align: left;
  padding-bottom: 20px;
}

.recently-view-section .slick-arrow.slick-prev {
  top: -84px;
  right: 9rem;
  left: unset;
}

.recently-view-section .slick-arrow.slick-next {
  top: -84px;
  right: 5.1rem;
}

.get-started-bg {
  background-image: url("data:image/svg+xml,%0A%3Csvg width='1920' height='380' viewBox='0 0 1920 380' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='1920' height='380' fill='%231773EA'/%3E%3Cpath d='M126.397 111.746L144.698 81.804L100.318 95.8071L126.397 111.746Z' fill='white' fill-opacity='0.8'/%3E%3Cpath d='M764.859 20.6908L782.999 30.8373L765.142 41.4735L764.859 20.6908Z' fill='%23E0F5F5'/%3E%3Cpath d='M1412.74 291.444L1431.9 299.493L1415.35 312.064L1412.74 291.444Z' fill='%23E0F5F5'/%3E%3Cpath d='M254.5 264L267.923 287.25H241.077L254.5 264Z' fill='%23E0F5F5'/%3E%3Cpath opacity='0.4' d='M1750 142.229L1809.97 157.979L1763.72 90.0005L1750 142.229Z' fill='white'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 40px 0;
}

.ready-to-go-text h2 {
  color: #fff;
  font-size: 48px;
  letter-spacing: 0.4px;
  font-family: "Montserrat", sans-serif;
}

.ready-to-go-text p {
  color: #fff;
  font-weight: 400;
  margin-bottom: 16px;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  line-height: 30px;
  letter-spacing: 0.4px;
}

.get-btn {
  background-color: #fff;
  color: #1773ea;
  font-family: "Montserrat", sans-serif;
  border: 1px solid #ffffff;
}

.get-btn:hover {
  background-color: transparent;
  color: #ffffff;
  border: 1px solid #ffffff;
}

.copyright-text p {
  color: #fff;
  font-weight: 400;
}

.footer-bottom-area ul li a,
.footer-bottom-area ul li a:hover {
  color: #fff;
}

.footer-bottom-area ul li::before {
  content: "";
  position: absolute;
  right: -12px;
  top: 5.6px;
  width: 1px;
  height: 14px;
  background-color: #fff;
}

.munim-ads-card {
  height: 250px;
  box-shadow: 0px 0px 15px 2px rgb(0 0 0 / 10%);
  border-radius: 11px;
  cursor: pointer;
}

.munim-ads-card h3 {
  color: #1773ea;
}

.single-footer-widget .social-link li a:hover {
  background-color: transparent;
}

.single-footer-widget .social-link li a img {
  width: 200px;
}

/* breadcurmbs changes */
.main-pages {
  position: relative;
}

.main-pages:after {
  content: "";
  background-image: url("data:image/svg+xml,%0A%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.225 14.025C6.075 13.875 6 13.725 6 13.5C6 13.275 6.075 13.125 6.225 12.975L10.2 9L6.225 5.025C5.925 4.725 5.925 4.275 6.225 3.975C6.525 3.675 6.975 3.675 7.275 3.975L11.775 8.475C12.075 8.775 12.075 9.225 11.775 9.525L7.275 14.025C6.975 14.325 6.525 14.325 6.225 14.025Z' fill='black'/%3E%3Cmask id='mask0_3376_9920' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='6' y='3' width='6' height='12'%3E%3Cpath d='M6.225 14.025C6.075 13.875 6 13.725 6 13.5C6 13.275 6.075 13.125 6.225 12.975L10.2 9L6.225 5.025C5.925 4.725 5.925 4.275 6.225 3.975C6.525 3.675 6.975 3.675 7.275 3.975L11.775 8.475C12.075 8.775 12.075 9.225 11.775 9.525L7.275 14.025C6.975 14.325 6.525 14.325 6.225 14.025Z' fill='white'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_3376_9920)'%3E%3Crect x='18' width='18' height='18' transform='rotate(90 18 0)' fill='%236E6B7B'/%3E%3C/g%3E%3C/svg%3E%0A");
  position: absolute;
  background-size: 22px;
  top: 1px;
  right: -24px;
  height: 22px;
  width: 22px;
  background-position: center;
}

.breadcrubs-content span {
  font-weight: 600;
  font-size: 16px;
  color: #1773ea;
}

/* services detail page */

.services-name h2 {
  font-size: 36px;
  color: #636363;
  font-weight: 600;
}

.user-detail-pannel .pro-user-img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 6px solid white;
  overflow: hidden;
  background: #ffffff;
  box-shadow: 0px 0px 16.0526px rgb(0 0 0 / 10%);
  top: -31px;
}

.user-detail-pannel .pro-user-img img {
  width: 48px;
  height: 48px;
  border-radius: 50%;
}

.user-name {
  font-size: 16px;
  color: #6b6b84;
  /* padding-left: 1rem; */
}

.user-name span {
  font-weight: 600;
}

.user-lf-line {
  position: relative;
}

.user-date,
.user-rating {
  color: #636363;
  font-weight: 500;
}

.user-lf-line:before {
  content: '';
  border-left: 1px solid #afafaf;
  position: absolute;
  height: 20px;
  left: -8px;
  top: 2px;
}

.ratting-star {
  padding: 0 4px;
}

.ratting-star+span {
  padding-left: 5px;
}

.services-detail-img img,
.services-detail-sub-img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.services-detail-img {
  height: 540px;
  width: 100%;
  object-fit: cover;
}

.services-detail-sub-img {
  /* height: 100px;
  width: 100%; */
  height: 80px;
  width: 110px !important;
  object-fit: cover;
}

.slickSlider2 .slick-list .slick-track .slick-slide>div {
  margin: 0 5px;
}

.slickSlider2 .slick-arrow.slick-prev {
  left: 40px;
}

.slickSlider2 .slick-arrow.slick-next {
  right: 40px;
}

.user-pro-img a {
  width: 100px;
  height: 100px;
  display: block;
}

.user-pro-img img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.user-pro-card-member {
  border-radius: 6px;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
}

.badge-icon {
  background-color: aqua;
  border-radius: 13px;
  padding: 3px 9px;
}

.badge-icon p {
  font-size: 13px;
  color: #1773ea;
}

.member-desc p {
  font-weight: 400;
  font-size: 16px;
}

/* services user table */
table {
  border-collapse: collapse;
  border-spacing: 0;
  text-indent: 0;
}

.packge-detail-table table {
  width: 100%;
  font-size: 14px;
  line-height: 21px;
  border: 1px solid #efeff0;
}

.packge-detail-table col {
  border-right: 1px solid #efeff0;
}

.packge-detail-table tr th {
  color: #74767e;
  font-weight: 500;
  text-align: left;
  padding: 12px 16px;
  vertical-align: text-top;
  border-right: 1px solid #EBE9F1;
}

.pro-munim-tble .munim-br-left {
  border-left: 1px solid #EBE9F1;
}

.packge-detail-table tr.package-type td {
  padding-top: 24px;
  position: relative;
}

.packge-detail-table tr.description td,
.packge-detail-table tr.package-type td {
  vertical-align: text-top;
  text-align: left;
  padding-left: 16px;
  padding-right: 16px;
  color: #636363;
}

.packge-detail-table tr.description td {
  font-weight: 400;
  padding-bottom: 2rem;
  font-size: 15px;
}

.packge-detail-table tr td {
  width: 25%;
  vertical-align: middle;
  text-align: center;
}

.packge-detail-table .package-type .price-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-bottom: 4px;
}

.packge-detail-table .package-type .price-wrapper .price {
  display: inline-block;
  font-weight: 600;
}

.packge-detail-table .package-type .price,
.packge-detail-table .package-type .type {
  font-size: 19px;
  line-height: 26px;
  color: #636363;
}

.packge-detail-table .package-type .type {
  padding-bottom: 16px;
}

.packge-detail-table .package-type b {
  display: block;
}

.packge-detail-table .package-type .title {
  text-transform: uppercase;
  font-size: 12px;
  line-height: 150%;
  padding-bottom: 8px;
  color: #636363;
}

.packge-detail-table .package-type,
.packge-detail-table .description {
  background-color: #F8FBFE;
  border: none;
}

.boolean-pricing-factor .pricing-factor-check-icon svg {
  width: 20px;
  height: 20px;
  fill: #1773ea;
}

.packge-detail-table tbody tr {
  border: 1px solid #EBE9F1;
  color: #636363;
  font-weight: 500;
  font-size: 17px;
}

.select-package td {
  padding: 15px;
}

.select-package td .price-wrapper .tbody-5 {
  padding-bottom: 1rem;
}

.btn-outline-default,
.btn-outline-default:hover {
  background-color: transparent;
  border: 1px solid #1773ea;
  color: #1773ea;
  padding: 7px 15px;
}

.section-area h3 {
  font-weight: 500;
}

.services-user-rating-label h5 {
  font-size: 15px;
  font-weight: 500;
}

.rating-textarea {
  border: 1px solid #D8D6DE;
  width: 100%;
  height: 160px;
  border-radius: 3px;
  resize: none;
  padding: 15px;
  color: #636363;
  font-size: 14px;
}

/* star rating reviews */
.stars-counters tr .progress-bar-container {
  width: 100%;
  padding: 0;
  margin: auto;
}

.progress-bar-container .star-progress-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.star-div-rating {
  position: relative;
  height: 8px;
  background: #e4e5e7;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  border-radius: 999px;
}

.star-div-rating span {
  background-color: #ffb33e
}

.reviews-package .stars-counters tr .star-progress-bar .star-progress-shape {
  background-color: #ffb33e;
}

.star-progress-shape {
  background: #1dbf73;
  height: 100%;
  position: absolute;
  left: 0;
  z-index: 1;
  border-radius: 999px;
}

.stars-filter-wrapper,
.star-num {
  /* padding: 5px; */
  font-size: 15px;
  font-weight: 400;
  color: #636363;
}

.star-rating-reviews .stars-filter-wrapper {
  white-space: nowrap;
}

/* .stars-counters tbody tr td:nth-child(1),
.stars-counters tbody tr td:nth-child(3) {
  width: 8%;
} */

/* .stars-counters tbody tr td:nth-child(3) {
  padding-left: 1rem;
} */

.rating-user-img .user-pro-img img {
  width: 40px;
  height: 40px;
}

.rating-username,
.review-users-sec span {
  font-weight: 600;
  color: #636363;
}

.user-subtitle {
  color: #636363;
  padding-top: 1rem;
  font-weight: 500;
}

.user-subtitle-publish {
  color: #636363;
  padding-top: 0.7rem;
  font-weight: 400;
}

.helpful-users {
  font-weight: 600;
  font-size: 15px;
  color: #636363;
  cursor: pointer;
}

.helpful-users:hover {
  font-weight: 600;
  font-size: 15px;
  color: #1773ea;
  cursor: pointer;
}

.services-details-info {
  border-radius: 6px;
  box-shadow: 0px 0px 15px 2px rgb(0 0 0 / 10%);
}

.services-details-info .tabs .tab-content {
  padding: 1.5rem;
}

.services-details-info .basic-packge-box .compare-packges .default-btn,
.service-contact-prof-button {
  padding: 7px 22px;
}

.nav-tabs .nav-link {
  color: #636363;
  background-color: #fff;
  border-color: #dee2e6;
  border-top: 0;
  border-radius: 0;
  font-weight: 600;
  padding: 13px 16px;
  cursor: pointer;
  text-align: center;
}

.nav-tabs .nav-item:nth-child(1) .nav-link,
.nav-tabs .nav-item:nth-child(3) .nav-link {
  border-right: 0;
  border-left: 0;
}

.nav-tabs .nav-item .nav-link:nth-child(2) {
  border: 1px solid #dee2e6;
  border-top: 0;
}

.nav-item .nav-link.active {
  color: #1773ea;
  border-bottom: 3px solid #1773ea;
  background-color: #fff;
  font-weight: 600;
}

.nav-tabs {
  border: 0;
}

.nav.nav-tabs {
  width: 100%;
  justify-content: start;
  width: 100%;
}

.nav.nav-tabs li {
  flex: 1;
}

.nav-item {
  /* text-align: center; */
  text-align: left;
}

.basic-packge h3 {
  font-size: 17px;
}

.basic-packge {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.days-pad-right {
  padding-left: 0.5rem;
  font-weight: 500;
  font-size: 15px;
  color: #636363;
}

.all-types-categories {
  font-size: 16px;
  color: #636363;
  padding-bottom: 10px;
  font-weight: 400;
}

.pricing-factor-check-icon {
  padding-right: 8px;
}

.listing-ul-price {
  margin-top: 1rem;
}

.all-types-categories .pricing-factor-check-icon svg {
  width: 20px;
  height: 20px;
  fill: #1773ea;
}

/* professional detilo page changes */
.pro-detail-card-body {
  border: 0;
  box-shadow: 0px 0px 15px 2px rgb(0 0 0 / 10%);
}

.pro-user-img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  margin: 0 auto;
  padding: 5px;
  box-shadow: 0px 0px 15px 2px rgb(0 0 0 / 10%);
}

.pro-user-img img {
  border-radius: 50%;
}

.pro-users-detail-box {
  position: relative;
}

.pro-users-detail-box .pro-card-favourite-icon {
  position: absolute;
  top: 5px;
  right: 10px;
}

.pro-users-detail-box .pro-card-favourite-icon .bx.bx-heart {
  font-size: 25px;
}

.border-bottom {
  border-bottom: 1px solid #D8D6DE;
}

.lan-list {
  text-align: left;
}

.map-detail .bx-map,
.map-detail span {
  color: #636363;
  font-weight: 600;
}

.map-detail span {
  font-size: 20px;
}

.map-detail img {
  width: 20px;
}

.hours-time-date ul {
  list-style: none;
  text-align: left;
  padding: 14px 23px;
  padding-bottom: 0;
  margin: 0;
}

.hours-time-date ul li {
  display: flex;
  gap: 10px;
  font-size: 17px;
  padding-bottom: 0.8rem;
  color: #636363;
  font-weight: 500;
}

.hours-time-date ul li span {
  flex: 1;
}

.hours-time-date ul li:nth-child(1) {
  font-weight: 700;
}

.pro-detail-services-list h3 {
  color: #1773ea;
  font-weight: 600;
  font-size: 24px;
}

.pro-servcies-body .professional-card,
.professional_services .professional-card {
  margin: 0;
  margin-bottom: 1.5rem;
}

.service-list .professional-card {
  margin-left: 0;
  margin-right: 0;
}

/*search services changes */
.pro-search-head .pro-search-head-card .pro-user-img {
  width: 50px;
  height: 50px;
}

.pro-serach-card-body {
  padding: 20px;
}

.pro-search-head-card .pro-card-favourite-icon i {
  font-size: 21px;
  color: #1773ea;
}

.search-location-info .map-detail .bx {
  font-size: 20px;
  color: #636363;
}

/* .search-location-info .map-detail p,
.search-location-info p {
  font-size: 16px;
} */

.pro-services-beadges {
  background-color: rgb(227 238 252);
  color: #1773ea;
  font-weight: 600;
  border-radius: 15px;
  text-align: center;
  margin-left: 4px;
}

.professional-service-card {
  border-radius: 6px;
  ;
}

/* services process page design [are you profeessional] */
.services-tabs-container {
  max-width: 1100px;
  margin: 0 auto;
}

.services-tabs-title h2 {
  font-weight: 500;
  font-size: 30px;
  color: #1773EA;
}

.services-card {
  border: 0;
  box-shadow: 0px 0px 15px 2px rgb(0 0 0 / 10%);
  border-radius: 6px;
}

.services-card .card-header {
  background-color: transparent;
}

.services-num-box {
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  display: flex;
  background: #EDEDED;
  border-radius: 4px;
  color: #B9B9C3;
  font-size: 14px
}

.services-tabs-text {
  font-weight: 600;
  font-size: 14px;
  color: #B9B9C3;
}

.services-process {
  position: relative;
  cursor: pointer;
}

.services-tabs-process {
  gap: 3.8rem;
}

.services-process::before {
  content: '';
  background-image: url("data:image/svg+xml,%0A%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 3.33337L12.6667 8.00004L8 12.6667' stroke='%236E6B7B' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  position: absolute;
  background-size: 17px;
  height: 20px;
  width: 20px;
  top: 10px;
  left: -50px;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  background-position: center;
}

.services-tabs-process .services-process:nth-child(1)::before {
  content: unset;
}

/* active tabs */
.services-process .active-tabs .services-tabs-text {
  color: #1773EA;
}

.services-process .active-tabs .services-num-box {
  background: #1773EA;
  color: #fff;
}

.form-label.form-label {
  color: #6E6B7B;
  margin-bottom: 0;
  font-weight: 400;
  font-size: 14px;
}

.identix-max-charat textarea,
.identix-max-charat textarea::placeholder {
  font-size: 14px;
  color: #636363;
  resize: none;
  padding: 0.8rem 3.5rem 0.8rem 1rem;
}

.identix-max-charat {
  position: relative;
}

.identix-max-charat .max-charat {
  position: absolute;
  bottom: 1px;
  height: calc(100% - 27px);
  display: flex;
  align-items: flex-end;
  color: #1773ea;
  font-weight: 700;
  background: 0 0;
  padding: 4px 10px;
  right: 15px;
}

.identix-max-charat textarea:focus,
.identix-max-charat textarea:focus-within {
  box-shadow: 0 3px 10px 5px rgba(34, 41, 47, .1);
  border-color: #1773ea;
}

.react-select .select__menu {
  z-index: 99999;
}

.react-select__control .select__indicator-separator,
.select__control .select__indicator-separator {
  display: none;
}

.react-select__control .select__placeholder,
.react-select__control .select__single-value,
.select__control .select__placeholder,
.select__control .select__single-value {
  color: #6e6b7b;
}

.select__control.select__control--is-focused,
.overview-search-tages .react-tags.is-focused,
.form-control:focus,
.setting-select-box .select__control.select__control--is-focused,
.budget-button:focus {
  border: 1px solid #1773ea;
  box-shadow: 0 3px 10px 5px rgba(34, 41, 47, .1);
  font-size: 14px;
}

.select__control.css-1s2u09g-control {
  font-size: 14px;
}

.btn-form-submit .default-btn {
  padding: 9px 25px;
}

/* pricing table */
.pricing-table table {
  border-radius: 5px;
}

.pricing-table tr.package-type td:not(td:nth-child(1)) {
  padding: 37px;
  text-align: center;
  width: 31.5%;
}

.packge-detail-table.pricing-table tr td {
  width: unset;
}

.packge-detail-table.pricing-table tr td:nth-child(1) {
  width: 40%;
}

.packge-detail-table.pricing-table .package-type .type {
  padding-bottom: 0;
  font-weight: 500;
}

.packge-detail-table.pricing-table tr td:nth-last-child(1) {
  position: relative;
}

.packge-detail-table.pricing-table tr td .delete-column {
  position: absolute;
  right: 7px;
  top: 9px;
}

.pricing-table table tbody tr:nth-child(2) th,
.pricing-table table tbody tr:nth-child(3) th {
  position: relative;
  padding-right: 1rem;
}

.packge-detail-table.pricing-table tr td:nth-child(1) {
  text-align: left;
}

.packge-detail-table.pricing-table .input-checkbox {
  text-align: center;
}

.packge-detail-table.pricing-table tr th {
  vertical-align: middle;
}

.svg-edit-icons {
  position: absolute;
  right: 5px;
  top: 5px;
}

.svg-edit-icons svg {
  width: 15px;
  cursor: pointer;
}

.packge-detail-table.pricing-table .react-select .select__control {
  border: none;
  box-shadow: none;
}

.packge-detail-table.pricing-table .react-select .select__indicator {
  color: #636363;
}

.packge-detail-table.pricing-table table tbody tr:nth-child(1) td:nth-child(1) {
  padding-bottom: 16px;
  vertical-align: bottom;
  background-color: #fff;
  /* border-right: 1px solid #EBE9F1; */
}

.add-more-btn svg {
  width: auto;
  height: 24px;
}

.packge-detail-table.pricing-table span,
.packge-detail-table.pricing-table .react-select .select__placeholder,
.packge-detail-table.pricing-table td,
.packge-detail-table.pricing-table div {
  font-size: 15px;
}

.pro-munim-tble {
  width: 100%;
}

/* gallery page */
.gallery-list .gallery-img {
  border-radius: 5px;
  width: 100%;
  height: 226px;
  border: 1px solid #EBE9F1;
  object-fit: cover;
}

.gallery-img.gallery-img-upload {
  border: 2px dashed #1773ea;
  display: flex;
  align-items: center;
  justify-content: center;
}

.gallery-img.gallery-img-upload h4,
.inquiry-link {
  font-weight: 500;
}

.gallery-img.gallery-img-upload p {
  font-weight: 600;
  color: #1773ea;
}

.gallery-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* 0093 */
.table-head-bg {
  background-color: #e8f1fd;
  color: #5E5873;
}

.table-head-bg tr th {
  padding: 8px 20px;
}

.reject-badge-bg {
  background-color: rgba(234, 84, 85, 0.12);
  ;
  color: #EA5455;
  font-size: 13px;
}

.live-badge-bg {
  background-color: rgba(40, 199, 111, 0.12);
  color: #28C76F;
  font-size: 13px;
}

.pending-badge-bg {
  background-color: rgba(255, 159, 67, 0.12);
  color: #FF9F43;
  font-size: 13px;
  display: flex;
  gap: 5px;
  width: max-content;
  margin: 0 auto;
}

.Under-discussion-badge-bg {
  background-color: rgba(0, 207, 232, 0.12);
  color: #00CFE8;
  font-size: 13px;
  display: flex;
  gap: 5px;
}

.Expired-badge-bg {
  background-color: rgba(0, 0, 0, 0.1);
  color: #000000;
  font-size: 13px;
}

/* <--------0105-start-css-----------> */
.inner-page-container {
  max-width: 1100px;
  margin: 0 auto;
}

.status-history-card h5 {
  font-weight: 500;
  font-size: 18px;
}

.status-history-card .timeline {
  border-left: 1px solid #e6e6e6;
  position: relative;
  list-style: none;
}

.status-history-card .timeline .timeline-item {
  position: relative;
}

.status-history-card .timeline .timeline-item:after {
  position: absolute;
  display: block;
  top: 0;
  background-color: #1773EA;
  left: -39px;
  border-radius: 50%;
  height: 13px;
  width: 13px;
  content: "";
  outline: 4px solid rgba(23, 115, 234, 0.15);
}

.status-history-card .badge.manager-badge {
  color: #00CFE8;
  background: rgba(0, 207, 232, 0.12);
  height: 20px;
  margin: 4px 8px;
}

.status-history-card .badge.manager-badge.own-badge {
  color: #28C76F;
  background: rgba(40, 199, 111, 0.12);
}

/* myaccount-page(inquiries-page-css-start) */
.myaccount-area .myaccount-card-listing {
  border-radius: 6px;
  border: none;
  box-shadow: 0px 0px 15px 2px rgb(0 0 0 / 10%);
}

.myaccount-area .nav-tabs .nav-link {
  border: none;
  padding: 15px 0 10px;
  margin: 0 20px;
  font-size: 14px;
  color: #5E5873;
  font-weight: 400;
  width: fit-content;
}

.dropdown-box .select__menu .select__menu-list .select__option {
  word-break: break-all;
}

.myaccount-area .nav-item .nav-link.active {
  border-bottom: 3px solid #1773ea;
  font-weight: 600;
}

.myaccount-area .nav.nav-tabs {
  width: 40%;
  justify-content: start;
  padding-bottom: 20px;
}

.myaccount-area .table>:not(:first-child) {
  border-top: 2px solid #dee2e6;
}

/* checked and unchecked icon */
.checked-un-icon>div>div>div {
  top: 0px;
  left: 2px;
  width: 12px !important;
  height: 12px !important;
}

.checked-un-icon>div .react-switch-handle {
  top: 5px !important;
  height: 14px !important;
  width: 14px !important;
  left: -5px !important;
}

.checked-un-icon.unchecked-icon>div .react-switch-handle {
  transform: translateX(8px) !important
}

.checked-un-icon.unchecked-icon>div>.react-switch-bg div:last-of-type {
  left: 20px !important;
}

.checked-un-icon>div .react-switch-bg {
  width: 42px !important;
  height: 24px !important;
}

/* myaccount-page(inquiries-page-css-end) */

.form-control.is-invalid:focus {
  box-shadow: 0 3px 10px 5px rgba(34, 41, 47, .1);
}

@media only screen and (max-width: 767px) {
  .status-history-card .flex-reverse {
    flex-wrap: wrap-reverse;
  }

  .myaccount-area .flex-device-column {
    flex-direction: column;
  }

  .identix-listing-img img {
    max-width: unset;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .identix-listing-img img {
    max-width: unset;
  }
}

/* <--------0105-end-css-----------> */

/* table start */
.table-row-pad tr td {
  padding: 20px;
  font-size: 14px;
  color: #6E6B7B;
}

.table-row-pad tr th {
  font-size: 12px;
  color: #6E6B7B;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding: 8px 20px;
}

.table-row-pad tr {
  vertical-align: baseline;
}

.dropdownitem-font-si,
.input-required-irror,
.fnt-14,
.tab-pane .gst-late-lbl {
  font-size: 14px;
}

.dropdownmenu-z-index {
  z-index: 99;
  min-width: 120px;
  border-radius: 8px;
  border: 0;
  box-shadow: 0px 0px 15px 2px rgb(0 0 0 / 10%);
  transform: unset !important;
  inset: unset !important;
  right: 0 !important;
}

.input-group .form-control:focus {
  box-shadow: none;
  border-color: unset;
}

.table-row-pad thead {
  background-color: #e8f1fd;
}

.gallery-img-height {
  height: 200px;
}

.profile-name-fnt,
.fnt-12 {
  font-size: 12px;
}

/* page not found text */
.page-not-found-text h3 {
  color: #1773EA;
  font-weight: 500;
  font-size: 30px;
}

.default-btn .spinner-border {
  color: #ffff !important;
}

/* ------Authenticated action and send_box modal css start_0105---- */
.send-box-modal .modal-dialog {
  max-width: 580px !important;
}

.send-box-modal .modal-title {
  font-size: 20px;
  font-weight: 500;
}

.send-box-modal .modal-content {
  border: none;
  border-radius: 6px;
}

.send-box-modal .modal-header {
  border-bottom: none;
  background-color: #F8F8F8;
}

.send-box-modal .btn-close {
  position: relative;
  top: 0;
  right: 0;
  /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
  opacity: 1;
  border: 1px solid #c7c3c3;
  background-color: #fff;
  padding: 12px;
  font-size: 11px;
}

.send-box-modal .btn-close:focus {
  box-shadow: unset;
}

.send-box-modal .border-bottom {
  border-bottom: 1px solid red;
}

.send-box-modal p {
  font-size: 16px;
  font-weight: 600;
}

.send-box-modal ul li {
  font-size: 14px;
  color: #6E6B7B;
  padding: 3px 0;
}

.send-box-modal ul li::marker {
  font-size: 10px;
  color: #6E6B7B;
}

.send-box-modal .form-label {
  margin-bottom: 15px;
  font-weight: 500;
}

.send-box-modal .width-texarea {
  width: 335px;
}

.send-box-modal .max-charat {
  right: 0;
}

.send-box-modal .avtarleady-img img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

/* ------Authenticated action and send_box modal css end_0105---- */


/* setting-page css start-0105  */
.setting-tabs-container {
  max-width: 560px;
  margin: 0 auto;
}

/* setting-page css End-0105  */

/* Navbar Accordion item reletead css start 0105 */
.accorion-menu {
  /* display: grid !important;
  grid-template-columns: repeat(5, 1fr);
  grid-template-columns: repeat(auto); */
  /* grid-auto-flow: column; */
  overflow: auto;
  padding: 10px !important;
  height: 500px;
}

.nav-bar-accordion .accordion-item {
  border: unset;
}

.nav-bar-accordion .accordion-button:focus {
  border: none;
  outline: none;
  box-shadow: none;
}

.nav-bar-accordion .accordion-button:not(.collapsed) {
  box-shadow: unset;
  background-color: #fff;
  color: #6e6b7b;
}

.nav-bar-accordion .accordion-button,
.accordion-body .list-group-item {
  color: #6e6b7b;
  font-weight: 600;
  border: unset;
  padding: 8px;
  font-size: 14px;
}

.nav-bar-accordion .accordion-body .list-group-item,
.fw-600 {
  font-weight: 600;
}

.nav-bar-accordion .accordion-body,
.services-area .slick-slider.slick-initialized,
.section-area .slick-slider.slick-initialized {
  padding: 0;
}

.nav-bar-accordion .accordion-button::after {
  background-size: 1rem;
  width: 1rem;
  height: 1rem;
}

.nav-bar-accordion .accordion-button:hover {
  color: #1773ea;
  background-color: #e3eefc;
}

.nav-bar-accordion .submenu {
  position: absolute;
  width: 195px;
  box-shadow: 0px 0px 15px 0px rgb(0 0 0 / 10%);
  z-index: 999999;
}

.menu-scrolbar::-webkit-scrollbar {
  width: 6px;
}

.menu-scrolbar::-webkit-scrollbar-track {
  background-color: #eee;
  border-radius: 100px;
  background-color: transparent;
  transition: background-color .2s linear, opacity .2s linear;
  -webkit-transition: background-color .2s linear, opacity .2s linear;
}

.menu-scrolbar::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 100px;
}

.pro-munim-submenu .accordion .accordion-item .accordion-header button::after {
  background-image: none;
}

.pro-munim-padd li a {
  padding: 8px 12px !important;
}

/* Navbar Accordion item reletead css end 0105 */
/*my account listing services */
.identix-listing-img {
  flex: 0.7;
}

.identix-listing-content {
  flex: 5;
}

.identix-sort-by {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.identix-sort-by .select__control,
.identix-sort-by .select__control.select__control--is-focused.select__control--menu-is-open {
  border: none;
  box-shadow: none;
}

.identix-sort-by .select__value-container {
  font-weight: 700;
}

/*budget button css*/
.budget-button {
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  cursor: default;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 20%;
  color: hsl(0, 0%, 50%);
  padding: 7px 7px;
  font-size: 14px;
}

.budget-button svg {
  color: hsl(0, 0%, 80%);
}

.budget-button svg:hover {
  color: hsl(0, 0%, 60%);
}

.budget-dropdown {
  position: absolute;
  z-index: 1;
  background: White;
  width: 330px;
  border: 1px solid #d4d5d7;
  margin-top: 10px;
  border-radius: 4px;
}

.budget-input-space {
  padding: 20px 24px;
  border-bottom: 1px solid #d4d5d7;
}

.budget-input-icon {
  top: 7px;
  right: 7px;
  color: #b5b6ba;
}

.budget-apply-section {
  padding: 15px 24px;
}

.budget-text-color {
  color: #95979d;
}

.service-contact-prof-button {
  border: 1px solid #6E6B7B;
  border-radius: 6px;
  background-color: #ffffff;
  font-size: 14px;
  color: #6E6B7B;
  font-weight: 500;
  cursor: pointer;
}

/* service model popup */
.service-model-close {
  top: -7px;
  right: -7px;
  box-shadow: 0px 3px 8px rgb(167 174 181 / 40%);
  border-radius: 6px;
  background: #FFFFFF;
  padding: 6px;
  line-height: normal;
  display: flex;
}

.service-model-txt-clr {
  color: #5E5873;
}

.send-box-modal .service-model-head-desc,
.send-box-modal .service-model-head,
.service-list .professional-service-card .pro-user-name h5,
.fnt-18 {
  font-size: 18px;
}

.service-model-fnt-14,
.send-box-modal .service-model-btn {
  font-size: 14px !important;
}

.service-model-footer-txt {
  color: #B9B9C3;
}

.service-model-input {
  padding: 8px 14px;
  border-radius: 5px;
  border-color: #D8D6DE;
}

.header-main-navbar .nav-item .nav-link.active {
  border: unset;
  color: #6E6B7B !important;
}

.send-box-modal .service-model-body-head {
  font-size: 25px;
}

.send-box-modal.service-model-popup .modal-dialog {
  max-width: 590px !important;
  width: 100%;
}

/*  Calculators page */
.calc-tab-color {
  background-color: #F0F3F7;
  color: #6E6B7B;
  border-radius: 5px;
  font-weight: 500;
  font-size: 16px;
}

.calc-tab-color:hover,
.nav-item .calc-tab-color.active {
  color: #fff;
  background-color: #1773EA;
  font-weight: 500;
}

.calc-card {
  width: 310px;
  margin: 0 10px 20px 10px;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
}

.calc-card .card-body {
  padding: 20px;
}

.calc-card-title {
  font-weight: 500;
}

.calc-card-txt {
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
}

.calc-card-txt-height {
  height: 117px;
}

.calc-tab-sticky {
  position: sticky;
  top: 150px;
}

/* QR code generator */
.qr-radio-btn {
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid #d8d6de;
  padding: 10px 10px 10px 15px;
  display: inline-flex;
  align-items: center;
  width: 140px;
  justify-content: space-between;
}

.qr-radio-label {
  font-size: 15px;
  color: #b9b9c3;
  font-weight: 400;
  line-height: 24px;
  width: 100px;
}

.qr-box-shadow {
  box-shadow: 0 4px 24px rgb(0 0 0 / 6%);
}

.qr-value-input {
  width: 84%;
}

.paragraph-txt-color {
  color: #6E6B7B;
}

.serch-box-mt {
  margin-top: 30px;
}

.identix-sort-by .select__control {
  caret-color: transparent;
}

.header-btn {
  padding: 11px 22px;
  font-size: 16px;
}

.navbar .header-main-navbar .nav-item .dropdown-menu.dropdown-menu-right {
  left: auto;
}

.tarn-nav .navbar .navbar-nav .nav-item .munim-notification-drop-down-sec .dropdown-menu {
  opacity: unset;
  visibility: unset;
  width: 425px;
  right: 0 !important;
  left: unset !important;
  display: none;
  max-height: 460px;
  overflow-y: auto;
}

.tarn-nav .navbar .navbar-nav .nav-item .munim-notification-drop-down-sec .dropdown-menu::-webkit-scrollbar {
  width: 8px;
}

.tarn-nav .navbar .navbar-nav .nav-item .munim-notification-drop-down-sec .dropdown-menu::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.tarn-nav .navbar .navbar-nav .nav-item .munim-notification-drop-down-sec .dropdown-menu::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 100px;
}

.tarn-nav .navbar .navbar-nav .nav-item .munim-notification-drop-down-sec .dropdown-menu::-webkit-scrollbar-track {
  background-color: #eee;
  width: 15px;
  border-radius: 100px;
}

.tarn-nav .navbar .navbar-nav .nav-item .munim-notification-drop-down-sec .dropdown-menu::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0);
  border-radius: 100px;
  transition: background-color .2s linear, opacity .2s linear;
}

.tarn-nav .navbar .navbar-nav .nav-item .munim-notification-drop-down-sec.pf-munim-click-notification .dropdown-menu {
  display: block;
}

.tarn-nav .navbar .navbar-nav .nav-item.munim-drop-down-sec .dropdown-menu {
  opacity: unset;
  visibility: unset;
  display: none;
}

.tarn-nav .navbar .navbar-nav .nav-item.munim-drop-down-sec.munim-click-dropdown .dropdown-menu {
  display: block;
}

.dropdown-menu-media .dropdown-menu-header {
  border-bottom: 1px solid #ebe9f1;
}

.dropdown-menu-media .dropdown-menu-header .dropdown-header {
  padding: 5px 10px 10px;
}

.media-list .list-item {
  padding: 0.9rem 1.28rem;
  border: none;
  border-bottom: 1px solid #ebe9f1;
}

.media-list .notification-items,
.munim-add-more-text .bx-trash,
.delete-column .bx-trash,
.cursor-pointer,
.react-select .select__menu .select__menu-list .select__option,
.select__indicator.select__dropdown-indicator:hover {
  cursor: pointer;
}

/* banner select dropdown */
.search-box-contrainer {
  display: flex;
  align-items: center;
}

.dropdown-ser-sec {
  flex: 1.3;
  display: flex;
  width: 184px;
  position: relative;
  align-items: center;
  border-left: 1px solid #d9d9d9;
}

.dropdown-ser-sec .react-select {
  position: unset;
}

.dropdown-ser-sec .react-select .select__menu {
  left: 8px;
}

.dropdown-ser-sec .react-select .select__menu .select__menu-list .select__option,
.react-select .select__menu .select__menu-list .select__option.select__option--is-focused,
.react-select .select__menu .select__menu-list .select__option {
  font-size: 14px;
  word-break: break-word;
}

.sticky-side-sec {
  height: 100% !important;
  position: sticky;
  top: 18%;
}

.services-dropdown-gap {
  column-gap: 16px;
  row-gap: 4px;
}

.services-dropdown-gap::-webkit-scrollbar {
  height: 6px;
}

/* gst late fees calculation  */
/*.gst-late-number::-webkit-outer-spin-button,
.gst-late-number::-webkit-inner-spin-button{
  -webkit-appearance: none;
}
.gst-late-number[type=number] {
  -moz-appearance: textfield;
}*/
.munim-ads-card .about-text-head {
  background-size: contain;
  max-width: 100%;
  background-repeat: no-repeat;
  width: 100%;
  background-position: center;
  border-radius: 10px;
}

/* pricing table */
.pro-munim-tble> :not(caption)>*>* {
  padding: 0;
}

table.pro-munim-tble tbody tr td.identix-second-tble {
  width: 22%;
}

table.pro-munim-tble tbody tr td.identix-second-tble {
  vertical-align: top;
}

table.pro-munim-tble tbody tr td.identix-second-tble .pricing-detail .packge-detail-table table .package-type td {
  background-color: #F8FBFE;
  padding: 37px;
  text-align: center;
}

table.pro-munim-tble tbody tr td:nth-child(1) {
  width: 50%;
  vertical-align: top;
}

.munim-add-more-text,
.border-right-pricing {
  border-right: 1px solid #EBE9F1;
}

.pro-munim-tble input.form-control,
.pro-munim-tble input.form-control:focus,
.pro-munim-tble textarea.form-control,
.pro-munim-tble textarea.form-control:focus,
.pro-munim-tble input.select__control.select__control.select__control--is-focused {
  border: unset;
  resize: none;
  box-shadow: unset;
  padding: 6px 0;
}

.identix-second-tble .pricing-detail .packge-detail-table table tbody tr,
.identix-second-tble .pricing-detail .packge-detail-table table {
  border-left: unset;
}

/* Chrome, Safari, Edge, Opera */
.pro-munim-tble input::-webkit-outer-spin-button,
.pro-munim-tble input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.bg-unset {
  background-color: unset;
}

.pointer-event-none {
  pointer-events: none;
}

.pro-munim-tble .input-checkbox input.form-check-input {
  margin: 0 !important;
}

.munim-add-more-text>div>input {
  padding: 0 !important;
}

.identix-second-tble .packge-detail-table.pricing-table .input-checkbox {
  padding: 17.5px 16px;
}

/* gallery img hover to show icon */
.gallery-img .gallery-img-hover-icon {
  display: none;
  top: 10px;
  right: 10px;
  background-color: #000000;
  border-radius: 5px;
  opacity: 0.7;
  line-height: 0;
  cursor: pointer;
}

.gallery-img:hover .gallery-img-hover-icon {
  display: block;
}

.services-details-image .slickSlider2.service-second-slider .slick-list .slick-track .slick-slide .services-detail-sub-img {
  position: relative;
}

.services-details-image .slickSlider2.service-second-slider .slick-list .slick-track .slick-slide>div>.services-detail-sub-img::after {
  content: '';
  background: rgb(0 0 0 / 52%);
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  border-radius: 5px;
}

.services-details-image .slickSlider2.service-second-slider .slick-list .slick-track .slick-slide.slick-active.slick-current>div>.services-detail-sub-img::after {
  content: unset;
}

.munim-view-article-desc .jodit-container .jodit-workplace>div,
.munim-view-article-desc .jodit-container {
  -webkit-user-modify: read-only;
  -moz-user-modify: read-only;
  user-modify: read-only;
  cursor: default;
  pointer-events: none;
}

/* service tab border */
.service-tab-bdr .nav-tabs .nav-item {
  position: relative;
}

.service-tab-bdr .nav-tabs .nav-item .nav-link.active {
  border-bottom: 0;
}

.service-tab-bdr .nav-tabs .nav-item .nav-link.active:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  border-bottom: 3px solid #1773ea;
  width: 100%;
}

.basic-packge-box p {
  line-height: 25px;
}

.pro-munim-tble .form-control.is-invalid {
  background-position-x: 88%;
}

/* favourite professionals user img  */
.service-list .professional-service-card .pro-user {
  width: 40px;
  height: 40px;
}

/* pagination */
.pagination .page-item.prev .page-link,
.pagination .page-item.next .page-link {
  border-radius: 50%;
  color: #0d6efd;
  font-size: 14px;
  font-weight: 500;
}

.pagination-next-icon-ml {
  margin-left: 10px;
}

.pagination-prev-icon-mr {
  margin-right: 10px;
}

.pagination .page-item .page-link {
  width: 32px;
  height: 32px;
  font-size: 14px;
  border: unset;
  color: #6E6B7B;
  background-color: #F3F2F7;
  display: flex;
  justify-content: center;
  font-weight: 500;
}

.pagination .page-item.active {
  background-color: #F3F2F7;
}

.pagination .page-item.active .page-link {
  border-radius: 50%;
  color: #ffffff;
  background-color: #0d6efd;
}

.pagination .page-item:nth-child(2) .page-link,
.pagination .page-item.active:nth-child(2) {
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}

.pagination .page-item:nth-last-child(2) .page-link,
.pagination .page-item.active:nth-last-child(2) {
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}

/* setting page select box icon */
.setting-select-box .select__control .select__indicators {
  display: none;
}

.setting-select-box .select__control {
  background-image: url(../images/setting/Group.svg);
  background-repeat: no-repeat;
  background-position: 94% 50%;
  width: 120px;
  height: 38px;
  border: 1px solid #D8D6DE;
  font-size: 14px;
}

/* progress bar */
.star-rating-reviews .progress-bar-container .progress {
  height: 10px;
  background-color: #E3EEFC;
  border-radius: 5px;
}

.star-rating-reviews .progress-bar-container .progress .progress-bar {
  border-radius: 5px;
}

/* slick slider */
.slickSlider2.service-second-slider .slick-list .slick-track {
  display: flex;
}

.slickSlider2.service-second-slider .slick-list .slick-track .slick-slide {
  /* width: 214px !important; */
  width: 120px !important;

}

/* language tags */
.badge-clr-info {
  color: #00CFE8;
  background-color: #E0F9FC !important;
}

.badge-clr-success {
  color: #28C76F;
  background-color: #E5F8EE !important;
}

.badge-clr-primary {
  color: #7367F0;
  background-color: #EEEDFD !important;
}

.overview-search-tages .react-tags {
  border-radius: 7px;
  padding: 0 6px;
}

.overview-search-tages .react-tags__selected-tag {
  margin-top: 6px;
}

/* pricing table error css */
.pro-munim-tble .invalid-feedback {
  position: absolute;
  bottom: -10px;
  font-size: 13px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

/* salary slip pdf start */
.salary-slip-container {
  width: 100%;
  max-width: 840px;
  margin: 20px auto 8px;
}

.salary-slip-row {
  box-shadow: 0px 0px 15px 2px rgb(0 0 0 / 10%);
  padding: 2rem;
}

.salary-slip-flex {
  display: flex;
  justify-content: space-between;
}

.salary-slip-header {
  gap: 10px;
}

.salary-slip-m-0 {
  margin: 0;
}

.salary-fnt-clr {
  color: #000000;
}

.salary-fnt-12 {
  font-size: 12px;
}

.salary-fnt-14 {
  font-size: 14px;
}

.salary-slip-txt-right {
  text-align: right;
}

.salary-slip-txt-center {
  text-align: center;
}

.salary-table .salary-bdr-top-0 {
  border-top: 0px !important;
}

.salary-table .salary-bdr-bottom-0 {
  border-bottom: 0px !important;
}

.salary-table .salary-bdr-right-0 {
  border-right: 0px !important;
}

.salary-table .salary-bdr-left-0 {
  border-left: 0px !important;
}

.salary-table .salary-bdr-0 {
  border: 0;
}

.salary-table {
  width: 100%;
  margin-top: 16px;
}

.salary-table td,
.salary-table th {
  border: 1px solid #000000;
  padding: 3px 6px;
}

.salary-table td {
  font-weight: 500;
}

.salary-table-data {
  display: flex;
}

/* salary slip pdf end */
.identix-web-gst-rates .identix-gst-btn .btn-secondary,
.identix-web-gst-rates .btn-secondary {
  background-color: #F0F3F7;
  border-color: #F0F3F7;
  color: #6E6B7B;
  font-weight: 500;
}

.identix-web-gst-rates .btn:focus,
.identix-web-gst-rates input:focus {
  outline: unset;
  box-shadow: unset;
}

.identix-goods-services-table .table thead tr th {
  vertical-align: middle;
  font-weight: 600;
}

.identix-goods-services-table .table thead tr th,
.identix-goods-services-table .table tbody tr td {
  padding: 10px 20px;
}

.identix-goods-services-table .table tbody tr td {
  font-weight: 400;
}

.identix-goods-services-table .table {
  box-shadow: 2px 6px 15px 6px rgb(0 0 0 / 5%);
}

/* video card */
.video-card-user-img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 5px solid white;
  overflow: hidden;
  background: #ffffff;
  box-shadow: 0px 0px 16.0526px rgb(0 0 0 / 10%);
}

.identix-emi-calculator input {
  padding-left: 25px;
}

.identix-text-flex {
  flex: 5;
}

.identix-amt-flex {
  flex: 1;
}

.identix-monthly-emi {
  position: absolute;
  width: 100%;
  bottom: -3rem;
  padding: 0 5rem;
}

.mb-6 {
  margin-bottom: 6rem !important;
}

.identix-fd-cal .identix-time-period>input {
  border-right: unset;
  border-top-right-radius: unset;
  border-bottom-right-radius: unset;
}

.identix-fd-cal .select__control {
  border-left: unset;
  border-top-left-radius: unset;
  border-bottom-left-radius: unset;
  box-shadow: unset;
}

.identix-fd-cal .select__value-container {
  padding: 0;
}

.identix-time-period>input {
  flex: 2;
}

.identix-time-period .react-select {
  flex: 4;
}

.identix-time-period .select__single-value {
  font-size: 14px;
}

.identix-time-period .select__control.select__control--is-focused.select__control--menu-is-open {
  border-left: unset;
}

.identix-reposnive-main-card {
  margin-bottom: 9rem !important;
}

/* home page recently views */
.identix-recently-slick .slick-slider {
  position: unset;
}

.identix-recently-slick .slick-arrow.slick-next {
  top: 8%;
  right: 0;
}

.identix-recently-slick .slick-arrow.slick-prev {
  top: 8%;
  left: unset;
  right: 60px;
}

.page-view-amt {
  padding-left: 6px;
  font-size: 16px;
  font-weight: 600;
}

.page-view-amt-img svg {
  width: 18px;
  height: auto;
}

.identix-listing-img img {
  margin-right: 5rem !important;
}

/* video detail card */
.video-detail-card {
  width: 100%;
  height: 400px;
  object-fit: cover;
  border-radius: 6px;
  position: relative;
  cursor: pointer;
}

.video-list-detail-card {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 6px;
  position: relative;
  cursor: pointer;
}

.video-detail-card .video-detail-card-img {
  width: 100%;
  height: 400px;
  object-fit: cover;
  border-radius: 6px;
}

.video-detail-card::before,
.video-list-detail-card::before {
  content: '';
  background-color: #000000;
  opacity: 0.2;
  position: absolute;
  height: 400px;
  width: 100%;
  z-index: 99;
  border-radius: 6px;
}

.video-list-detail-card::before {
  height: 100%;
  opacity: 0.5;
}

.video-detail-icon {
  position: absolute;
  width: 100%;
  top: 50%;
  z-index: 99;
  transform: translateY(-50px);
}

.video-detail-icon>div>svg,
.video-list-detail-card .video-detail-icon svg {
  transition: transform .5s ease;
  cursor: pointer;
}

.video-detail-card:hover .video-detail-icon>div>svg,
.video-list-detail-card:hover .video-detail-icon svg {
  transition: transform .5s ease-in-out;
  transform: scale(1.2);
}

.video-list-detail-card .video-detail-icon svg {
  width: 75px;
}

.identixweb-video-detail-badge .badge {
  margin: 0;
}

.video-like-count {
  margin-left: 10px;
}

.services-page-layout .user-professional .user-pro-name h4 {
  color: #1773EA;
  font-weight: 600;
}

.add-video-tabs {
  flex: 1;
}

.add-video-add-box {
  flex: 4;
}

.border-left-card {
  border-left: 1px solid #cecece;
}

.my-video-tabs,
.calc-tab-color {
  color: #B9B9C3;
  font-weight: 600;
  font-size: 16px;
}

.identix-tab-active .my-video-tabs {
  color: #1773EA;
  font-weight: 600;
  font-size: 16px;
}

.identix-border-card {
  border: unset;
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
}

/* video detail page */
.identixweb-video-detail-badge .user-name i {
  font-size: 18px;
}

.user-pro-card-member .member-desc ul {
  line-height: 25px;
}

.user-pro-card-member .member-desc ul li {
  color: #6E6B7B;
}

.user-pro-card-member .member-desc ul li::marker {
  font-size: 8px;
}

.user-world-icon {
  top: 18px;
  right: 18px;
}

.video-popup-icon {
  padding: 10px;
  border: 1px solid #1773ea;
  border-radius: 50%;
}

.save-video-popup .btn-close {
  background-size: 14px;
}

.save-video-popup .modal-dialog {
  width: 250px;
}

.identix-reducing-badge .bg-success.rounded-pill {
  background-color: rgb(40 199 111 / 12%) !important;
  color: #28C76F;
}

.identix-reducing-badge .identix-monthly-emi {
  bottom: -6rem;
}

/* pending badge button */
.article-status .article-badge-btn {
  font-size: 13px;
  display: flex;
  gap: 5px;
  width: max-content;
  margin: unset;
}

.pending-badge-bg {
  background-color: rgba(255, 159, 67, 0.12);
  color: #FF9F43;
}

.sidebar-main-content ul li:hover,
.sidebar-main-content ul li:focus {
  cursor: pointer;
  background-color: #ddebff;
  color: #000;
}

.sec-area-table-min {
  min-height: 466px;
}

.overviewe-tabs .identix-max-charat textarea::placeholder {
  padding: 0px;
}

/* home page search select box */
.search-box-contrainer .dropdown-ser-sec .select__menu-list::-webkit-scrollbar,
body::-webkit-scrollbar {
  width: 7px;
}

.search-box-contrainer .dropdown-ser-sec .select__menu-list::-webkit-scrollbar-track,
body::-webkit-scrollbar-track {
  background: rgb(242, 242, 242);
}

.search-box-contrainer .dropdown-ser-sec .select__menu-list::-webkit-scrollbar-thumb,
body::-webkit-scrollbar-thumb {
  background: #d3d3d3;
  border-radius: 3px;
}

.identix-price-input .form-control {
  padding-left: 26px;
}

/* footer social icon width */
.footer-social-icon {
  width: 40px;
}

/* calculator search input width*/
.calculator-search-input {
  max-width: 600px;
}

.calculator-search-input span {
  right: 12px;
}

.services-tag {
  color: hsl(0deg 0% 20%);
  background-color: #e0e0e0;
  cursor: pointer;
  font-size: 12px;
  margin: 2px 4px;
  padding: 5px 7px;
  box-shadow: 0 2px 4px 0 hsl(0deg 0% 100% / 50%);
  max-width: fit-content;
  border-radius: 6px;
}

/* calculator range */
#input-range-slider {
  background: linear-gradient(to right, #1773EA 0%, #1773EA 0%, rgba(23, 115, 234, 0.12) 0%, rgba(23, 115, 234, 0.12) 100%);
  border-radius: 8px;
  height: 6px;
  outline: none;
  transition: 450ms ease-in;
  -webkit-appearance: none;
}

.invalid-tooltip {
  right: 10px;
  font-size: 10px !important;
  line-height: normal;
  padding: 4px 6px;
}

.form-control {
  font-size: 14px;
}

.overviewe-tabs .invalid-tooltip {
  right: 0;
}

.identix-input-error {
  border: 1px solid red !important;
  cursor: pointer;
}

.identix-input-error input,
.identix-input-error textarea,
.identix-input-error .select__control {
  cursor: pointer;
}

.fnt-weight-500 {
  font-weight: 500 !important;
}

.fnt-weight-600 {
  font-weight: 600 !important;
}

.munim-pro-txt-secondary {
  color: #B9B9C3;
}

.gallery-section-title {
  font-size: 20px;
}

.pro-about-pofile-description {
  letter-spacing: 1px;
}

.about-professional-content .user-pro-card-member .member-desc p {
  line-height: 26px;
  letter-spacing: 0.5px;
}

.packge-detail-table.pricing-table .identix-input-error:hover .invalid-tooltip {
  opacity: 1;
  top: 38px;
  right: 20px;
}

.packge-detail-table.pricing-table .identix-input-error:hover .react-select+.invalid-tooltip {
  opacity: 1;
  top: 25px;
  right: -6px;
}

.packge-detail-table.pricing-table .identix-input-error:hover input[placeholder="Price"]+.invalid-tooltip {
  opacity: 1;
  top: 25px;
  right: -6px;
}

.packge-detail-table.pricing-table .identix-input-error.munim-input:hover input+.invalid-tooltip {
  opacity: 1;
  top: 25px;
  right: 10px;
}

.packge-detail-table.pricing-table .invalid-tooltip {
  opacity: 0;
}

.fnt-17 {
  font-size: 17px !important;
}

.table-user-img {
  width: 50px;
  height: 50px;
  display: block;
}

.table-user-img img {
  width: 100%;
  height: 100%;
}

.chat-application .sidebar-content .chat-user-list-wrapper li.active,
.content-right .chat-app-window .chats .chat-body .chat-content {
  background-color: #1773ea !important;
  background-image: unset !important;
}

.chat-application .content-area-wrapper .sidebar-content .chat-list-title {
  color: #1773ea;
}

.content-right .content-body .chat-content {
  display: flex;
  gap: 10px;
}

.content-right .chat-app-window .chats .chat-body .chat-content p {
  font-size: 13px;
  font-weight: 400;
  margin-top: 5px;
  display: flex !important;
  align-items: end;
  width: 70px;
}

.content-right .chat-app-window .chats .chat-body .chat-content p {
  color: #fff !important;
}

.pro-servcies-no-data {
  height: 92%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  box-shadow: 0px 0px 15px 2px rgb(0 0 0 / 10%);
}

.select__control.select__control--is-focused,
.select__control.css-1s2u09g-control:hover {
  cursor: text;
}

.identix-web-link .dropdown-menu .media-list:hover {
  background-color: #f8f8f8;
}

.content-right .chat-app-window .chat.chat-left .chat-body .chat-content,
.content-right .chat-app-window .chat.chat-left .chat-body .chat-content p {
  background-color: #fff !important;
  color: #6e6b7b !important;
}

.chats .admin-box .admin-content span {
  background: #b3d7ed !important;
  color: #212529 !important;
}

/* media query */
@media (max-width: 767px) {
  body {
    overflow-x: hidden;
  }

  .munim-top-header p {
    font-size: 13px;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .banner-wrapper-content h1 {
    font-size: 30px !important;
    margin-bottom: 18px;
    color: #636363;
    letter-spacing: 0.5px;
    line-height: 43px;
  }

  .identix-web-gst-rates>.row {
    row-gap: 1rem;
  }

  .identix-monthly-emi {
    position: unset;
    flex-direction: column;
    padding: 0;
  }

  .identix-reposnive-card {
    padding: 1.5rem !important;
    margin-bottom: 1rem;

  }

  .identix-reposnive-main-card {
    margin: 15px !important;
  }

  .identix-text-flex {
    display: flex;
    align-items: center;
  }

  .identix-amt-flex {
    flex: 2;
  }

  .identix-text-flex {
    flex: 2;
  }

  .identix-amt-flex>span {
    top: 11px !important;
  }

  .identix-responsive-emi-cal {
    flex: unset;
  }

  .identix-recently-slick .slick-arrow.slick-next {
    right: -4px;
    top: 50%;
  }

  .identix-recently-slick .slick-arrow.slick-prev {
    left: -4px;
    top: 50%;
  }

  .packge-detail-table.pricing-table .form-control {
    width: unset;
  }

  .service-user-pannel-review {
    flex-direction: column;
    align-items: baseline !important;
    gap: 4px !important;
  }

  .service-user-pannel-review .user-lf-line:before {
    content: unset;
  }

  .user-pro-img a {
    width: 80px;
    height: 80px;
  }

  .mb-sm-10 {
    margin-bottom: 10px;
  }

  .send-message-wrap {
    flex-direction: column;
  }

  .send-message-wrap .identix-max-charat textarea {
    min-height: 170px;
  }

  .send-message-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #dee2e6;
    margin-bottom: 8px;
  }

  .send-message-info .avtarleady-img img {
    width: 90px;
    height: 90px;
  }

  .send-message-info ul {
    padding-left: 1rem !important;
  }

  .send-message-info .border-bottom {
    border: none !important;
  }

  .slick-list .services-detail-img {
    height: 290px;
  }

  .slickSlider2.service-second-slider .slick-list .slick-track {
    justify-content: center;
  }

  .budget-dropdown {
    width: 100%;
  }
  .send-box-modal .width-texarea {
      width: auto;
  }
  .user-pro-card-member .p-4 .d-flex {
    flex-wrap: wrap;
}
.table-row-pad tr td{
  min-width: 230px;
}
}

.sidebar-main .second {
  height: fit-content !important;

}

body #react-sidebar .sidebar-main {
  left: unset !important;
  transform: translateX(300px);
  right: 0;
}



@media (min-width: 768px) and (max-width: 991px) {
  .seo-agency-banner {
    padding-top: 0 !important;
    padding-bottom: 40px !important;
  }

  .seo-agency-banner .tab-reverse-sec {
    flex-direction: column-reverse;
  }

  .identix-monthly-emi {
    position: unset;
  }

  .identix-reposnive-card {
    padding: 1.5rem !important;
    margin-bottom: 1rem;
  }

  .identix-monthly-emi {
    padding: 0;
  }

  .professional-detail-list {
    margin-top: 20px;
  }


}

@media (min-width: 1024px) and (max-width: 1200px) {
  .hours-time-date ul {
    padding: 14px 0;
  }
}

@media (max-width: 991px) {
  .navbar-area .tarn-nav {
    padding: 10px 0;
  }

  .mobile-view-welcome .position-absolute {
    position: relative !important;
    text-align: center;
    padding: 50px 0px 20px 0px !important;
    margin: 0 !important;
  }

  .mobile-view-welcome .card-title,
  .mobile-view-welcome .card-text {
    text-align: center;
  }

  .munim-about-section .about-section-card {
    margin-bottom: 20px !important;
  }
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}