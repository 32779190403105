.avatar {
    white-space: nowrap;
    background-color: #d1e3fb;
    border-radius: 50%;
    position: relative;
    cursor: pointer;
    color: white;
    display: inline-flex;
    font-size: 1rem;
    text-align: center;
    vertical-align: middle;
    font-weight: 600;

    // avatar content
    .avatar-content {
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        font-size: 0.857rem;
        color: #177cef;

        .avatar-icon {
            height: 1rem;
            width: 1rem;
        }
    }

    [class*='avatar-status-'] {
        border-radius: 50%;
        width: 11px;
        height: 11px;
        position: absolute;
        right: 0;
        bottom: 0;
        border: 1px solid white;

        &.avatar-status-lg,
        &.avatar-status-xl {
            width: 17px;
            height: 17px;
            border-width: 2px;
        }
    }

    .avatar-status-online {
        background-color: #42ba96;
    }

    .avatar-status-busy {
        background-color: #df4759;
    }

    .avatar-status-away {
        background-color: #ff9966;
    }

    .avatar-status-offline {
        background-color: #FF6600;
    }

    // avatar label with basic size
    // using in FAQ & other pages
    &.avatar-tag {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 42px;
        width: 42px;
        border-radius: 50%;
    }

    // avatar img
    img {
        border-radius: 50%;
    }

    // Size Modifier
    // ========================================================================

    &.avatar-xl {
        font-size: 1.5rem;

        img {
            width: 70px;
            height: 70px;
        }

        .avatar-content {
            height: 70px;
            width: 70px;
            font-size: 2.57rem;

            .avatar-icon,
            i,
            svg {
                height: 3rem;
                width: 3rem;
                font-size: 3rem;
            }
        }

        [class*='avatar-status-'] {
            right: 3px;
            bottom: 0;
        }
    }

    &.avatar-lg {
        font-size: 1.2rem;

        img {
            width: 50px;
            height: 50px;
        }

        .avatar-content {
            width: 50px;
            height: 50px;
            font-size: 1.714rem;

            .avatar-icon,
            i,
            svg {
                height: 2rem;
                width: 2rem;
                font-size: 2rem;
            }
        }

        [class*='avatar-status-'] {
            right: 3px;
            bottom: 1px;
        }
    }

    &.avatar-sm {
        .avatar-content {
            width: 24px;
            height: 24px;

            .avatar-icon,
            i,
            svg {
                height: 1rem;
                width: 1rem;
                font-size: 1rem;
            }
        }

        img {
            width: 24px;
            height: 24px;
        }

        [class*='avatar-status-'] {
            right: -3px;
            bottom: -2px;
        }
    }
}

// Avatar Group SCSS
.avatar-group {
    display: flex;
    justify-content: flex-start;

    .avatar {
        transition: all 0.25s ease;

        img,
        .avatar-content {
            box-shadow: 0 0 0 2px white, inset 0 0 0 1px rgba(black, 0.07);
        }

        .avatar-content {
            background-color: tint-color(#FF6600, 20%) !important;
        }

        &:hover {
            transition: all 0.25s ease;
        }

        &.pull-up:hover {
            transform: translateY(-4px) scale(1.07);
        }
    }

    // Avatar Group Sizings
    .avatar:not(:first-child) {
        margin-left: -0.55rem;
    }

    .avatar-sm:not(:first-child) {
        margin-left: -0.4rem;
    }

    .avatar-lg:not(:first-child) {
        margin-left: -1rem;
    }

    .avatar-xl:not(:first-child) {
        margin-left: -1.5rem;
    }
}