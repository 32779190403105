/*
Flaticon icon font: Flaticon
*/
@font-face {
	font-family: "Flaticon";
	src: url("../fonts/Flaticon.eot");
	src: url("../fonts/Flaticon.eot?#iefix") format("embedded-opentype"),
	url("../fonts/Flaticon.woff2") format("woff2"),
	url("../fonts/Flaticon.woff") format("woff"),
	url("../fonts/Flaticon.ttf") format("truetype"),
	url("../fonts/Flaticon.svg#Flaticon") format("svg");
	font-weight: normal;
	font-style: normal;
}
@media screen and (-webkit-min-device-pixel-ratio:0) {
	@font-face {
		font-family: "Flaticon";
		src: url("../fonts/Flaticon.svg#Flaticon") format("svg");
	}
}
[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
	font-family: Flaticon;
	font-style: normal;
}

.flaticon-user:before { content: "\f100"; }
.flaticon-right:before { content: "\f101"; }
.flaticon-refresh:before { content: "\f102"; }
.flaticon-left:before { content: "\f103"; }
.flaticon-right-1:before { content: "\f104"; }
.flaticon-left-1:before { content: "\f105"; }
.flaticon-right-quote:before { content: "\f106"; }
.flaticon-left-quote:before { content: "\f107"; }
.flaticon-calendar:before { content: "\f108"; }
.flaticon-rocket:before { content: "\f109"; }
.flaticon-laptop:before { content: "\f10a"; }
.flaticon-money:before { content: "\f10b"; }
.flaticon-google-play:before { content: "\f10c"; }
.flaticon-loupe:before { content: "\f10d"; }
.flaticon-up:before { content: "\f10e"; }
.flaticon-tick:before { content: "\f10f"; }
.flaticon-agendas:before { content: "\f110"; }
.flaticon-team:before { content: "\f111"; }
.flaticon-factory:before { content: "\f112"; }
.flaticon-hospital:before { content: "\f113"; }
.flaticon-tracking:before { content: "\f114"; }
.flaticon-investment:before { content: "\f115"; }
.flaticon-house:before { content: "\f116"; }
.flaticon-order:before { content: "\f117"; }
.flaticon-family-insurance:before { content: "\f118"; }
.flaticon-bitcoin:before { content: "\f119"; }
.flaticon-segmentation:before { content: "\f11a"; }
.flaticon-analytics:before { content: "\f11b"; }
.flaticon-settings:before { content: "\f11c"; }
.flaticon-analysis:before { content: "\f11d"; }
.flaticon-structure:before { content: "\f11e"; }
.flaticon-idea:before { content: "\f11f"; }
.flaticon-earth-day:before { content: "\f120"; }
.flaticon-history:before { content: "\f121"; }
.flaticon-shopping-basket:before { content: "\f122"; }
.flaticon-price-tag:before { content: "\f123"; }
.flaticon-teacher:before { content: "\f124"; }
.flaticon-stopwatch:before { content: "\f125"; }
.flaticon-distance-education:before { content: "\f126"; }
.flaticon-web:before { content: "\f127"; }
.flaticon-open-padlock:before { content: "\f128"; }
.flaticon-share:before { content: "\f129"; }
.flaticon-notebook:before { content: "\f12a"; }
.flaticon-lock:before { content: "\f12b"; }
.flaticon-book:before { content: "\f12c"; }
.flaticon-shopping-cart:before { content: "\f12d"; }
.flaticon-view:before { content: "\f12e"; }